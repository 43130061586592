<template>
    <div>
        <div class="container">
            <div class="copy-container center-xy">
                <p>
                    404, page not found.
                </p>
                <span class="handle"></span>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
export default {
    name: "Pagenotfound",
    data() {
        return {

        }
    },
    mounted() {
    
    }
}
</script>
<style lang="scss" scoped>


</style>